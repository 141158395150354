import React, { useState } from 'react'
import './style.scss'
import { useInView } from 'react-intersection-observer'
import { useForm } from 'react-hook-form'
import axios from 'axios'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBaby } from '@fortawesome/free-solid-svg-icons'

import Block from '../../../Sections/BlocksRouter'
import Intro from '../../../../animations/Intro'

function FormBookNow(props) {
	const formImage = props.img
	const { register, handleSubmit, reset, formState: { errors } } = useForm()
	const [message, setMessage] = useState(false)
	const [isLoading, setLoading] = useState(false)
	const delayIn = 500
	const [io, ioInView] = useInView({ triggerOnce: true })

	const onSubmit = (form, e) => {
		e.preventDefault()
		setLoading(true)

		// Left is CF7 input name, right is ours
		let data = new FormData()
		data.append('First Name', form.fname)
		data.append('Last Name', form.lname)
		data.append('Email', form.email)
		data.append('Phone', form.phone)
		data.append('Number of Guests', form.guests)
		data.append('Event Type', form.eventType ? form.eventType : "")
		data.append('Preferred Date', form.date)
		data.append('How Did You Hear About Us', form.source ? form.source : "")
		data.append('Describe Event or Group', form.message)
		
		const url = `https://formspree.io/f/mknyvjlq`
		const config = { headers: { 'Content-Type': 'multipart/form-data' } }

		axios.post(url, data, config)
			.then(response => {
					console.log('status', response.data.status)
					console.log('response', response.data)
					if(response.data.ok) {
						setMessage("Submission sent")
						reset()
					}
					setLoading(false)
		
				}
			)
			.catch(errors => {
					console.log(errors)
				}
			)
	}

	return (
		<>
		<form
			name="contact"
			method="post"
			action="/thanks/"
			className="book-now"
			onSubmit={handleSubmit(onSubmit)}
			ref={io}
		>
			<div className="half">
				<label>
					<Intro visible={ioInView} in={{ border: 500, fade: 500 }} delayIn={ delayIn } className="border-top-off border-left-off border-right-off">
						<input type="text" placeholder="first name" name="fname" {...register("fname",{required: true, maxLength: 80, message: "error message"})} />
					</Intro>
					{errors.fname && <p className="small margin-off">your first name is required.</p>}
				</label>
				<label>
					<Intro visible={ioInView} in={{ border: 500, fade: 500 }} delayIn={ delayIn + 150 } className="border-top-off border-left-off border-right-off">
						<input type="text" placeholder="last name" name="lname" {...register("lname",{required: false, maxLength: 80, message: "error message"})} />
					</Intro>
					{errors.lname && <p className="small margin-off">your last name is required.</p>}
				</label>
				<label>
					<Intro visible={ioInView} in={{ border: 500, fade: 500 }} delayIn={ delayIn + 250 } className="border-top-off border-left-off border-right-off">
						<input type="text" placeholder="email" name="email" {...register("email",{required: true, minLength: 6, pattern: /^\S+@\S+$/i})} />
					</Intro>
					{errors.email && <p className="small margin-off">a correct email is required.</p>}
				</label>
				<label>
					<Intro visible={ioInView} in={{ border: 500, fade: 500 }} delayIn={ delayIn + 350 } className="border-top-off border-left-off border-right-off">
						<input type="text" placeholder="phone" name="phone" {...register("phone",{required: true, maxLength: 80, message: "error message"})} />
					</Intro>
					{errors.phone && <p className="small margin-off">your phone number is required.</p>}
				</label>
				<label>
					<Intro visible={ioInView} in={{ border: 500, fade: 500 }} delayIn={ delayIn + 450 } className="border-top-off border-left-off border-right-off">
						<input type="text" placeholder="Number of Guests" name="guests" {...register("guests",{required: true, maxLength: 80, message: "error message"})} />
					</Intro>
					{errors.guests && <p className="small margin-off">Number of Guests is required.</p>}
				</label>
				<div>
					<Intro visible={ioInView} in={{ blink: 500, fade: 500 }} delayIn={ delayIn + 550 } >
					<p className="checkbox-heading">Event type</p>		
						<div className="checkbox-container">
							<label className="inline">
								<input type="checkbox"  value="Corporate" {...register("eventType",{required: false, maxLength: 80, message: "error message"})}/>
								<span className="checkmark">
									<Intro visible={ioInView} in={{ fade: 100, draw: 1250 }} delayIn={30}  className="with-svg mark x">
										<svg xmlns="http://www.w3.org/2000/svg" width="30.412" height="34.188" viewBox="0 0 30.412 34.188">
											<g transform="translate(-708.549 -430.775)">
												<path d="M114.326,350.256a119.8,119.8,0,0,1,16.019,25.294" transform="translate(603.742 82.55)" fill="none" stroke="black" strokeMiterlimit="10" strokeWidth="2"/>
												<path d="M108.2,382.472a126.144,126.144,0,0,1,29.305-33.211" transform="translate(600.986 82.102)" fill="none" stroke="black" strokeMiterlimit="10" strokeWidth="2"/>
											</g>
										</svg>
									</Intro>
								</span>
								<p>Corporate</p>
							</label>
							<label className="inline">
								<input type="checkbox"  value="Non-Profit" {...register("eventType",{required: false, maxLength: 80, message: "error message"})}/>
								<span className="checkmark">
									<Intro visible={ioInView} in={{ fade: 100, draw: 125 }} delayIn={30}  className="with-svg mark x">
										<svg xmlns="http://www.w3.org/2000/svg" width="33.607" height="31.613" viewBox="0 0 33.607 31.613">
											<g transform="translate(-819.921 -242.525)">
												<path d="M184.879,220.506a338.105,338.105,0,0,1,32.613,28.966" transform="translate(635.503 24.138)" fill="none" stroke="black" strokeMiterlimit="10" strokeWidth="2"/>
												<path d="M215.8,219.433l-30.078,26.448" transform="translate(635.881 23.655)" fill="none" stroke="black" strokeMiterlimit="10" strokeWidth="2"/>
											</g>
										</svg>
									</Intro>
								</span>
								<p>Non Profit</p>
							</label>
							<label className="inline">
								<input type="checkbox"  value="Private/Wedding" {...register("eventType",{required: false, maxLength: 80, message: "error message"})}/>
								<span className="checkmark">
									<Intro visible={ioInView} in={{ fade: 100, draw: 125 }} delayIn={30}  className="with-svg mark x">
										<svg xmlns="http://www.w3.org/2000/svg" width="48.349" height="49.757" viewBox="0 0 48.349 49.757">
											<g transform="translate(-699.924 -149.882)">
												<path d="M144.2,159.485l-42.077,30.463" transform="translate(598.246 -3.332)" fill="none" stroke="black" strokeMiterlimit="10" strokeWidth="2"/>
												<path d="M105.584,155.507q21.594,24,42.308,48.774" transform="translate(599.806 -5.123)" fill="none" stroke="black" strokeMiterlimit="10" strokeWidth="2"/>
											</g>
										</svg>
									</Intro>
								</span>
								<p>Private / Wedding</p>
							</label>
							<label className="inline">
								<input type="checkbox"  value="Other" {...register("eventType",{required: false, maxLength: 80, message: "error message"})}/>
								<span className="checkmark">
									<Intro visible={ioInView} in={{ fade: 100, draw: 125 }} delayIn={30}  className="with-svg mark x">
										<svg xmlns="http://www.w3.org/2000/svg" width="48.349" height="49.757" viewBox="0 0 48.349 49.757">
											<g transform="translate(-699.924 -139.882)">
												<path d="M144.2,159.485l-42.077,30.463" transform="translate(598.246 -3.332)" fill="none" stroke="black" strokeMiterlimit="10" strokeWidth="2"/>
												<path d="M105.584,155.507q21.594,24,42.308,48.774" transform="translate(599.806 -5.123)" fill="none" stroke="black" strokeMiterlimit="10" strokeWidth="2"/>
											</g>
										</svg>
									</Intro>
								</span>
								<p>Other</p>
							</label>
						</div>
					</Intro>
				</div>
				<label>
					<Intro visible={ioInView} in={{ border: 500, fade: 500 }} delayIn={ delayIn + 650 }>		
						<textarea rows="8" placeholder="Describe your event or group" name="message" {...register("message")} />
					</Intro>
				</label>
			</div>
			<div className="half">
			<label>
				<Intro visible={ioInView} in={{ border: 500, fade: 500 }} delayIn={ delayIn + 750 } className="border-top-off border-left-off border-right-off">		
					<input type="text" onFocus={(e) => e.currentTarget.type = "date"}  placeholder="Preferred date MM/DD/YYYY" name="date"  {...register("date",{required: true, maxLength: 80, message: "error message"})} />
				</Intro>
				{errors.date && <p className="small margin-off">date is invalid</p>}
			</label>
			<div>
				<Intro visible={ioInView} in={{ blink: 500, fade: 500 }} delayIn={ delayIn + 850 } >	
					<p className="checkbox-heading">How did you hear about us</p>		
					<div className="checkbox-container">
						<label className="inline">
							<input type="checkbox"  value="Search Engine" {...register("source",{required: false, maxLength: 80, message: "error message"})}/>
							<span className="checkmark">
								<Intro visible={ioInView} in={{ fade: 100, draw: 125 }} delayIn={30}  className="with-svg mark x">
									<svg xmlns="http://www.w3.org/2000/svg" width="48.349" height="49.757" viewBox="0 0 48.349 49.757">
										<g transform="translate(-699.924 -139.882)">
											<path d="M144.2,159.485l-42.077,30.463" transform="translate(598.246 -3.332)" fill="none" stroke="black" strokeMiterlimit="10" strokeWidth="2"/>
											<path d="M105.584,155.507q21.594,24,42.308,48.774" transform="translate(599.806 -5.123)" fill="none" stroke="black" strokeMiterlimit="10" strokeWidth="2"/>
										</g>
									</svg>
								</Intro>
							</span>
							<p>Search engine</p>
						</label>
						<label className="inline">
							<input type="checkbox"  value="Wedding Venue Listing Site" {...register("source",{required: false, maxLength: 80, message: "error message"})}/>
							<span className="checkmark">
								<Intro visible={ioInView} in={{ fade: 100, draw: 125 }} delayIn={30}  className="with-svg mark x">
									<svg xmlns="http://www.w3.org/2000/svg" width="33.607" height="31.613" viewBox="0 0 33.607 31.613">
										<g transform="translate(-819.921 -242.525)">
											<path d="M184.879,220.506a338.105,338.105,0,0,1,32.613,28.966" transform="translate(635.503 24.138)" fill="none" stroke="black" strokeMiterlimit="10" strokeWidth="2"/>
											<path d="M215.8,219.433l-30.078,26.448" transform="translate(635.881 23.655)" fill="none" stroke="black" strokeMiterlimit="10" strokeWidth="2"/>
										</g>
									</svg>
								</Intro>
							</span>
							<p>Wedding venue listing site</p>
						</label>
						<label className="inline">
							<input type="checkbox"  value="Referral" {...register("source",{required: false, maxLength: 80, message: "error message"})}/>
							<span className="checkmark">
								<Intro visible={ioInView} in={{ fade: 100, draw: 125 }} delayIn={30}  className="with-svg mark x">
									<svg xmlns="http://www.w3.org/2000/svg" width="30.412" height="34.188" viewBox="0 0 30.412 34.188">
										<g transform="translate(-708.549 -430.775)">
											<path d="M114.326,350.256a119.8,119.8,0,0,1,16.019,25.294" transform="translate(603.742 82.55)" fill="none" stroke="black" strokeMiterlimit="10" strokeWidth="2"/>
											<path d="M108.2,382.472a126.144,126.144,0,0,1,29.305-33.211" transform="translate(600.986 82.102)" fill="none" stroke="black" strokeMiterlimit="10" strokeWidth="2"/>
										</g>
									</svg>
								</Intro>
							</span>
							<p>Referral</p>
						</label>
						<label className="inline" >
							<input type="checkbox"  value="Trade Show or Conference" {...register("source",{required: false, maxLength: 80, message: "error message"})}/>
								<span className="checkmark" >
								<Intro visible={ioInView} in={{ fade: 100, draw: 125 }} delayIn={30}  className="with-svg mark x">
									<svg xmlns="http://www.w3.org/2000/svg" width="48.349" height="49.757" viewBox="0 0 48.349 49.757">
										<g transform="translate(-699.924 -139.882)">
											<path d="M144.2,159.485l-42.077,30.463" transform="translate(598.246 -3.332)" fill="none" stroke="black" strokeMiterlimit="10" strokeWidth="2"/>
											<path d="M105.584,155.507q21.594,24,42.308,48.774" transform="translate(599.806 -5.123)" fill="none" stroke="black" strokeMiterlimit="10" strokeWidth="2"/>
										</g>
									</svg>
								</Intro>
							</span>
							<p>Trade show or conference</p>
						</label>
						<label className="inline">
							<input type="checkbox"  value="Repeat Client" {...register("source",{required: false, maxLength: 80, message: "error message"})}/>
								<span className="checkmark">
									<Intro visible={ioInView} in={{ fade: 100, draw: 125 }} delayIn={30}  className="with-svg mark x">
										<svg xmlns="http://www.w3.org/2000/svg" width="30.412" height="34.188" viewBox="0 0 30.412 34.188">
											<g transform="translate(-708.549 -430.775)">
												<path d="M114.326,350.256a119.8,119.8,0,0,1,16.019,25.294" transform="translate(603.742 82.55)" fill="none" stroke="black" strokeMiterlimit="10" strokeWidth="2"/>
												<path d="M108.2,382.472a126.144,126.144,0,0,1,29.305-33.211" transform="translate(600.986 82.102)" fill="none" stroke="black" strokeMiterlimit="10" strokeWidth="2"/>
											</g>
										</svg>
									</Intro>
								</span>
							<p>Repeat client</p>
						</label>
						<label className="inline">
							<input type="checkbox"  value="Other" {...register("source",{required: false, maxLength: 80, message: "error message"})}/>
							<span className="checkmark">
								<Intro visible={ioInView} in={{ fade: 100, draw: 125 }} delayIn={30}  className="with-svg mark x">
									<svg xmlns="http://www.w3.org/2000/svg" width="33.607" height="31.613" viewBox="0 0 33.607 31.613">
										<g transform="translate(-819.921 -242.525)">
											<path d="M184.879,220.506a338.105,338.105,0,0,1,32.613,28.966" transform="translate(635.503 24.138)" fill="none" stroke="black" strokeMiterlimit="10" strokeWidth="2"/>
											<path d="M215.8,219.433l-30.078,26.448" transform="translate(635.881 23.655)" fill="none" stroke="black" strokeMiterlimit="10" strokeWidth="2"/>
										</g>
									</svg>
								</Intro>
							</span>
							<p>Other</p>
						</label>
						<label className="inline">
							<input type="checkbox"  value="Advertisement" {...register("source",{required: false, maxLength: 80, message: "error message"})}/>
							<span className="checkmark">
								<Intro visible={ioInView} in={{ fade: 100, draw: 125 }} delayIn={30}  className="with-svg mark x">
									<svg xmlns="http://www.w3.org/2000/svg" width="33.607" height="31.613" viewBox="0 0 33.607 31.613">
										<g transform="translate(-819.921 -242.525)">
											<path d="M184.879,220.506a338.105,338.105,0,0,1,32.613,28.966" transform="translate(635.503 24.138)" fill="none" stroke="black" strokeMiterlimit="10" strokeWidth="2"/>
											<path d="M215.8,219.433l-30.078,26.448" transform="translate(635.881 23.655)" fill="none" stroke="black" strokeMiterlimit="10" strokeWidth="2"/>
										</g>
									</svg>
								</Intro>
							</span>
							<p>Advertisement</p>
						</label>
					</div>
				</Intro>
			</div>
			<Intro visible={ioInView} in={{ blink: 500, fade: 500 }} delayIn={ delayIn + 950 } >		
				<button className="submit" type="submit">SUBMIT YOUR REQUEST {isLoading ? <div className="loader-spinner"><FontAwesomeIcon icon={faBaby} /></div> : null}</button>
			</Intro>
			<p className="message small">{message}</p>
			
			<Intro visible={ioInView} in={{ border: 500, bg: 500, fade: 500 }} delayIn={ delayIn + 850 } >		
				{formImage ? <Block {...formImage[0]} /> : null}
			</Intro>
			</div>
		</form>
      {/* <iframe
        src={"https://cloud.experience.gulfstreampark.com/Bookanevent"}
        style={{ order: '100', width: '100vw', maxWidth: '100%', margin: '0 auto', border: 'none', marginTop: '2em', }}
        title={"Book Event"}
        className='book-iframe'
      /> */}

			{/* <iframe
				src="https://mcdg8xmk7lvyntmhlr6vs3w15q1m.pub.sfmc-content.com/m0lm3rlmdzb"
				frameBorder="0"
				style={{backgroundColor: '#ffffff', width: '100vw', maxWidth: '100%', padding: '1em 3.5em', margin: '0 auto', minHeight: '730px'}}
				title="Contact form"
			/> */}
		</>
	)
}

export default FormBookNow